<template>
    <div class="textblock" :class="[config.theme]" :_uid="_uid">
        <div v-if="!config.componentType || config.componentType=='template'" :style="[{'padding-top': `${config.layout.paddingTop}px`},{'padding-bottom': `${config.layout.paddingBottom}px`},{'margin-top': `${config.layout.marginTop}px`},{'margin-bottom': `${config.layout.marginBottom}px`},{'flex-direction':config.layout.display}]" class="textblock-container" :class="[`section-layout-${config.layout.display}`, `section-ratio-${config.layout.ratio}`, config.background, config.theme]">

                <div class="textblock-section" v-for="(sectionRecord, sectionIndex) in config.section" :style="{'align-self':sectionRecord[0] && sectionRecord[0].config.alignSelf ? sectionRecord[0].config.alignSelf : 'center'}"  :key="`section-${sectionIndex}`">

                    <div v-for="(contentRecord, contentIndex) in sectionRecord" :style="[{display:contentRecord.config.contentType=='button' ? contentRecord.content[lang].display : 'block'}]" :key="`section-${sectionIndex}-content-${contentIndex}`">
                        <Title v-if="contentRecord.config.contentType=='title'" :config="contentRecord.content" class="title" :language="language"/>
                        <!-- FOR IF HTML: if contentRecord.config.columns.number==1, don't add "columns" to style attribute. Gives odd inline-block-like behaviour that messes up the formatting of header elements. -->
                        <div v-else-if="contentRecord.config.contentType=='html' && contentRecord.config.columns.number==1" class="content-column"
                            :class="[contentRecord.config.columns.fill]"
                            :style="{
                                'max-height': contentRecord.config.columns.maxHeight ? contentRecord.config.columns.maxHeight+'px' : 'none'
                            }"
                            v-html="getContent(contentRecord.content)"
                        />
                        <!-- FOR IF HTML: else, add add "columns" to style attribute -->
                        <div v-else-if="contentRecord.config.contentType=='html'" class="content-column"
                            :class="[contentRecord.config.columns.fill]"
                            :style="{
                                '-webkit-columns': contentRecord.config.columns.number,
                                '-moz-columns': contentRecord.config.columns.number,
                                'columns': contentRecord.config.columns.number,
                                'max-height': contentRecord.config.columns.maxHeight ? contentRecord.config.columns.maxHeight+'px' : 'none'
                            }"
                            v-html="getContent(contentRecord.content)"
                        />
                        <div v-else-if="contentRecord.config.contentType=='button'" :style="[{display:contentRecord.content.display}]" :class="[`button-align-${getContent(contentRecord.content).align}`,contentRecord.config.buttonTheme ? `button-${contentRecord.config.buttonTheme}` : '']">
                            <v-btn :class="[`v-size--${getContent(contentRecord.content).size}`]" @click="buttonHandler(getContent(contentRecord.content))">
                                {{getContent(contentRecord.content).text}}
                            </v-btn>
                        </div>
                        <Icon v-else-if="contentRecord.config.contentType=='icon'" :config="contentRecord.content" class="icon"/>
                        <template v-else>
                            <component :is="contentRecord.config.contentType" :debug="debug" :componentConfig="contentRecord" :config="contentRecord.content" :language="language" :site="$root.site"/>
                        </template>
                    </div>

                </div>

        </div>


        <div v-if="config.componentType=='grid'">
            <div :class="[config.layout, config.background, config.theme]">
                <template v-for="(contentComponent, contentComponentIndex) in config.contentComponents">
                    <component :is="contentComponent.key" :debug="debug" :language="language" :componentConfig="contentComponent" :config="contentComponent.config" :key="`contentComponent-${contentComponentIndex}-${contentComponent.key}`"/>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import ComponentGrid from '@/components/Grid/ComponentGrid'
import VideoGrid from '@/components/Grid/VideoGrid'
import ImageGrid from '@/components/Grid/ImageGrid'
// import Title from '../title.vue'
import Title from '@/components/Title/Title'
import Stories from '@/components/Stories/StoriesCarousel.vue'
import StoryViewer from '@/components/Stories/StoryViewer.vue'
import Articles from '../News/NewsSection.vue'
import Partners from '@/components/Section/Partners/Partners'
import Resources from '@/components/Resources/Resources'
import Icon from './lib/icon.vue'
import ContactUs from '@/components/ContactUs/ContactUs'
import PodCast from '@/components/PodCast/PodCast'
import FindUs from '@/components/FindUs/find_us'
import Twitter from '@/components/Twitter/Twitter'
export default {
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false
        },
        debug: {
            type: Boolean,
            required: false,
            default: ()=>{return false}
        }
    },
    components: {
        Title,
        Partners,
        ComponentGrid,
        VideoGrid,
        ImageGrid,
        Icon,
        Stories,
        StoryViewer,
        Articles,
        Resources,
        ContactUs,
        PodCast,
        FindUs,
        Twitter
    },
    mounted: function(){
        this.initScripts()
        this.give_scrollingWrapper_toTables();
    },
    methods: {
        initScripts: async function(){
            let self = this
            async function init(){
                let uid = self._uid
                let contents = document.querySelectorAll(`[_uid="${uid}"]`)
                let allowed = [
                    'https://www.buzzsprout.com',
                    'https://platform.twitter.com'
                ]

                for(let c=0; c<contents.length; c++){
                    let content = contents[c]
                    let scripts = content.getElementsByTagName('script')

                    for(let i=0; i<scripts.length; i++){
                        let script = scripts[i]
                        let nodeValue = script.attributes.src.nodeValue
                        for (let index = 0; index < allowed.length; index++) {
                            const allow = allowed[index];

                            if(nodeValue.indexOf(allow)==0){
                                let response = await self.sendRequest('get',nodeValue)
                                if(response.status==200){
                                    let externalCode = response.data
                                    eval(externalCode)
                                }
                            }

                        }
                    }
                }
            }
            setTimeout(() => {
                init()
            }, 800);
        },
        nav: function(button){
            if(!button.link.newTab){
                let language = this.lang
                this.$router.push({path:button.link[language]})
            }
        },
        getContent: function(content){
            let language = this.lang
            return content[language]

        },
        componentSearch: function(str){
            var found = [],          // an array to collect the strings that are found
                // rxp = /{{([^}]+)}}/g,
                rxp = /```([^]+)```/g,
                curMatch;

            while( curMatch = rxp.exec( str ) ) {
                found.push( curMatch[1] );
            }
            return found
        },
        buttonHandler: function(content){
            console.log('buttonHandler',content)
            if(!content.internal){
                window.open(content.url)
            }else{
                this.$router.push({path:content.path})
            }
        },
        give_scrollingWrapper_toTables: function(){
            // GET: tables
            let tables = document.querySelectorAll('.textblock table');
            // LOOP: tables
            for(let i = 0; i < tables.length; i++){
                let table = tables[i];
                // IS: table NOT without givenTableScrollingWrapper ?
                if( table.getAttribute('givenTableScrollingWrapper') == null){
                    // GIVE: table givenTableScrollingWrapper flag
                    table.setAttribute('givenTableScrollingWrapper', true);
                    // MAKE: tableCopy within tableScrollingWrapper div
                    let tableCopy = `
                        <div class='tableScrollingWrapper'>
                            ${table.outerHTML}
                        </div>
                    `;
                    // GIVE: tableCopy to html
                    table.insertAdjacentHTML('afterend', tableCopy);
                    // REMOVE: original table from html
                    table.remove();
                };
            };
        }
    },
    computed: {
        lang: function(){
            let language = this.language
            if(language){
                return language
            }else{
                return this.$store.getters.language
            }
        },
        title: function(){
            let language = this.lang
            return this.config.title && this.config.title[language] ? this.config.title[language] : null
        },
        subtitle: function(){
            let language = this.lang
            return this.config.subtitle && this.config.subtitle[language] ? this.config.subtitle[language] : null
        },
        content: function(){
            let language = this.lang
            return this.config.content[language]
        },
        color: function(){
            return this.config.background == 'theme-darkblue' ? 'white' : ''
        }
    },
    watch: {
        config: {
            deep: true,
            handler: function(){
                this.initScripts()
            }
        }
    }
}
</script>

<style lang="scss">

.textblock {

    .textblock-container {
        width: 87%;

        // GENERAL
        .textblock-section {
            padding: 16px 24px;
            @media(max-width: 576px){
                padding: 16px 8px;
            }

            .wrap_pageCards {
                width: 100%;
            }

            .title {
                margin-bottom: 24px;

                .section-title-container {
                    @media(max-width: 599px){
                        display: block;
                    }
                }

                h2 {
                    margin-bottom: 24px;
                }

                .section-subtitle {
                    margin: initial;
                    padding: initial;
                    font-size: 19px;
                    font-weight: 600;
                }

                .decoration {
                    margin: -16px 16px 0;
                }

                .decoration:first-of-type {
                    @media(max-width: 599px){
                        display: none;
                    }
                }
                .decoration:nth-of-type(2) {
                    @media(max-width: 599px){
                        display: block;
                        margin: 16px 0;
                    }
                }
            }

            p {
                background-color: transparent !important;
                // font-family: Roboto, Arial, sans-serif !important;
                // font-size: 15px !important;
                // font-weight: 500 !important;
                // line-height: 28px !important;
                // font-size: 15px;
                font-weight: 500;
                line-height: 28px;

                span {
                    background-color: transparent !important;
                    // font-family: Roboto, Arial, sans-serif !important;
                    // font-size: 15px !important;
                    // font-weight: 500 !important;
                    // line-height: 28px !important;
                    // font-size: 15px;
                    font-weight: 500;
                    // line-height: 28px;
                }

                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin: 0 auto;
                }

                iframe {
                    width: 100%;
                    max-width: 880px;
                }
            }

            button {
                max-width: 100%;
                height: auto;
                margin: 8px 8px 8px 0;
                padding: 8px;
                word-break: break-word;

                span {

                    width: 100%;
                    white-space: normal;
                }
            }
        }

        // FOR RATIO LAYOUTS
        &.section-layout-row {

            @media(max-width: 1199px){
                width: 84%
            }
            @media(max-width: 959px){
                width: 92%;
            }

            .textblock-section {
                // over-rides any textblock ratio rules at this breakpoint
                @media(max-width: 767px){
                    width: 100% !important;
                }

                .decoration:first-of-type {
                    @media(max-width: 599px){
                        display: none !important;
                    }
                }
                .decoration:nth-of-type(2) {
                    @media(max-width: 599px){
                        display: block !important;
                        margin: 16px 0 !important;
                    }
                }
            }
        }
    }

    // HANDLE RED THEME DIFFERENCES
    &.theme-red {
        background-color: $red !important;
        color: white;

        // title
        h2 {
            color: white;
        }
        // title left & right line decoration
        .decoration {
            border: 2px solid white !important;
        }
        // title underline decoration
        hr.small {
            border: 2px solid white !important;
        }
    }
}


// section-ratio-1 title is centered, but then mobile-phone is left-aligned
.textblock {
    .section-ratio-1 {
        .title {
            h2 {
                text-align: center;
                @media(max-width: 599px){
                    text-align: left;
                }
            }
        }
    }
}


.textblock {

    .section-ratio-1-2,
    .section-ratio-1-3,
    .section-ratio-2-1,
    .section-ratio-3-1 {

        .section-title-container {
            display: block;

            .decoration:first-of-type {
                display: none !important;

            }

            .decoration:nth-of-type(2) {
                display: block !important;
                margin: 16px 0 !important;
            }
        }
    }

    .section-ratio-1 {

        iframe {
            // min-height: 440px;
        }
    }
}


.textblock {

    .articleCard {
        button.pin {
            height: 36px !important;
        }
    }

    .resource-container {
        button.pin {
            height: 36px !important;

            i:before {
                font-size: 24px;
            }
        }

        .v-avatar {
            span.v-btn__content {
                i {

                    &.mdi-play {
                        margin-left: 7px;
                    }

                    &.mdi-link {
                        margin-left: 0px;
                    }

                    &.mdi-file-download-outline {
                        margin-left: 2px;
                    }
                }
            }
        }

        iframe {
            min-height: initial;
        }
    }
}

.textblock {

    .section-ratio-1-2,
    .section-ratio-1-3,
    .section-ratio-2-1,
    .section-ratio-3-1 {

        iframe {
            max-width: 640px !important;
        }
    }
}

.textblock {

    .section-ratio-1 {

        // columns general
        div[style^="columns:"]{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            > p {
                flex-grow: 0.6;
                width: initial;

                &:not(:last-of-type){
                    margin-right: 4px;
                }
            }
        }

        // 1 column
        div[style^="columns: 1;"] {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;

            p {
                flex-grow: initial;
                width: 100% !important;

                iframe {
                    margin: 0 auto;
                    display: block;
                }
            }
        }
    }
}




.textblock.theme-blue{
    background-color: $secondary-light-2;
}

.textblock.theme-darkblue{
    background-color: $primary;
    color: white;
    font-weight: bold;

    p {
        background-color: transparent !important;
    }
}



.textblock-container{
    display: flex;
    flex-wrap: wrap;
    width: 76%;
    margin: 0 auto;
}

.content-column {
    display: block;
    -webkit-columns: 1;
    -moz-columns: 1;
    columns: 1;
}

.content-column.balance {
  -webkit-column-fill: balance;
  -moz-column-fill: balance;
  column-fill: balance;
}

.content-column.auto {
  -webkit-column-fill: auto;
  -moz-column-fill: auto;
  column-fill: auto;
}

.textblock-section{
    padding: 15px;
}

.section-ratio-1-3 .textblock-section:first-child{
    width: 25%;
}

    .section-ratio-1-3 .textblock-section:nth-child(2){
        width: 75%;
    }

.section-ratio-1-2 .textblock-section:first-child{
    width: 33%;
}

    .section-ratio-1-2 .textblock-section:nth-child(2){
        width: 66%;
    }

.section-ratio-3-1 .textblock-section:first-child{
    width: 75%;
}

    .section-ratio-3-1 .textblock-section:nth-child(2){
        width: 25%;
    }

.section-ratio-2-1 .textblock-section:first-child{
    width: 66%;
}

    .section-ratio-2-1 .textblock-section:nth-child(2){
        width: 33%;
    }

.section-ratio-1-1 .textblock-section:first-child{
    width: 50%;
}
    .section-ratio-1-1 .textblock-section:nth-child(2){
        width: 50%;
    }

.section-ratio-1 .textblock-section:first-child{
    width: 100%;
}
    .section-ratio-1 .textblock-section:nth-child(2){
        width: 100%;
    }



.textblock-section .title{
    margin-bottom: 50px;
}


.theme-white .v-btn,
.theme-red .v-btn {
    // background-color: $primary !important;
    color: white !important;
}


.v-btn{
    font-weight: bold !important;
}
.button-theme-darkblue .v-btn{
    background-color: white !important;
    color: white !important;
}


.button-theme-blue .v-btn{
    background-color: $secondary-light-2 !important;
}

.button-theme-darkblue .v-btn{
    background-color: $primary !important;
    color: white;
    font-weight: bold;
}

.button-theme-red .v-btn{
    background-color: $red !important;
    color: white;
}

.button-align-left{
    text-align: left;
}

.button-align-center{
    text-align: center;
}

.button-align-right{
    text-align: right;
}


/****
TABLE
*****/
.textblock {

    .tableScrollingWrapper {
        width: 100%;
        overflow-x: scroll;
    }

    table {
        width: auto !important;
        min-width: 960px;

        @media(max-width:959px){
            min-width: initial;
        }

        td {
            width: auto !important;
            padding: 8px 16px !important;
        }
    }
}

</style>
